import React from 'react';
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../locales/custom-localized-link";

const BANManagement = ({product}) => {
  const {t} = useTranslation();
  return (
      <div className="container bam-management">
        <h1 dangerouslySetInnerHTML={{__html:t('management')}} />
        {
          product !== 'Pivot'
            ? <>
                <p dangerouslySetInnerHTML={{__html: product + ' ' + t('management_headline_copy')}}/>
              </>
              : <>
                <p dangerouslySetInnerHTML={{__html: t('pivot_management_headline')}}/>
              </>
        }

        {
          product !== 'Pivot'
            ?
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center is-reversable">
                  <StaticImage
                      src="../../images/management/yellow-black-keys.png"
                      loading={'lazy'}
                      width={116}
                      height={159}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="management keys"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <div className="management-copy">
                    <h3><Trans>basic_management</Trans></h3>
                    <p><Trans>basic_management_copy</Trans></p>
                    <ul>
                      <li dangerouslySetInnerHTML={{__html: t('basic_management_list_one')}}/>
                      { product !== 'Orbit'
                          ? <>
                            <li dangerouslySetInnerHTML={{__html: t('basic_management_list_two')}}/>
                            <li dangerouslySetInnerHTML={{__html: t('basic_management_list_three')}}/>
                          </>
                          : <>
                            <li><Trans>orbit_bam_yellow_key</Trans></li>
                            <li><Trans>orbit_bam_assign_users</Trans></li>
                            <li><Trans>orbit_bam_man_override</Trans></li>
                          </>
                      }
                    </ul>
                  </div>
                </div>
              </div>
              :
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center is-reversable">
                  <StaticImage
                      src="../../images/management/management-cards.png"
                      loading={'lazy'}
                      width={256}
                      height={188}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="management cards"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <div className="management-copy">
                    <h3><Trans>basic_management</Trans></h3>
                    <p><Trans>pivot_management_subhead</Trans></p>
                    <ul>
                      <li><Trans>pivot_management_1</Trans></li>
                      <li><Trans>pivot_management_2</Trans></li>
                      <li><Trans>pivot_management_3</Trans></li>
                    </ul>
                  </div>
                </div>
              </div>
        }

        {
          product !== 'Pivot'
            ?
              <div className="grid-two-col">
                <div className="grid-two-col-item-single center is-reversable">
                  <StaticImage
                      src="../../images/management/pad-with-dongle.png"
                      loading={'lazy'}
                      width={284}
                      height={277}
                      quality={100}
                      formats={["auto", "webp", "avif"]}
                      alt="management keys"
                  />
                </div>
                <div className="grid-two-col-item-single">
                  <div className="management-copy">
                    <h3><Trans>advanced_management</Trans></h3>
                    <p><Trans>advanced_management_copy</Trans></p>
                    <ul>
                      <li dangerouslySetInnerHTML={{__html: t('advanced_management_list_one_two')}}/>
                      <li dangerouslySetInnerHTML={{__html: t('advanced_management_list_three')}}/>
                      <li dangerouslySetInnerHTML={{__html: t('advanced_management_list_four')}}/>
                      <li dangerouslySetInnerHTML={{__html: t('advanced_management_list_five')}}/>
                    </ul>
                  </div>
                </div>
              </div>
              :
              <></>
        }
        <div className="grid-two-col">
          <div className="grid-two-col-item-single center is-reversable">
            <StaticImage
                src="../../images/management/management-pad.png"
                loading={'lazy'}
                width={288}
                height={209}
                quality={100}
                formats={["auto", "webp", "avif"]}
                alt="Digilink"
            />
          </div>
          <div className="grid-two-col-item-single">
            <div className="management-copy">
              <h3><Trans>networked_management</Trans></h3>
              {
                product !== 'Pivot'
                  ?
                    <p dangerouslySetInnerHTML={{__html:t('networked_management_copy')}} /> :
                    <p dangerouslySetInnerHTML={{__html:t('pivot_networked_management_copy')}} />
              }

              <ul>
                <li dangerouslySetInnerHTML={{__html: t('networked_management_list_one')}}/>
                <li dangerouslySetInnerHTML={{__html: t('networked_management_list_two')}}/>
                <li dangerouslySetInnerHTML={{__html: t('networked_management_list_two_two')}}/>
                <li dangerouslySetInnerHTML={{__html: t('networked_management_list_three')}}/>
                <li><CustomLocalizedLink goto={'/blog/post/digilock-soc2-iso-certifications/'} label={t('networked_management_list_four')} /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  )
}
export default BANManagement;